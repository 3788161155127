export const fentanylSchema = [
  // {
  //   component: 'h3',
  //   children: 'Patient Information',
  //   class: 'tw-text-2xl tw-mb-4',
  // },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'First name',
        name: 'firstName',
        key: 'firstName',
      },
      {
        type: 'text',
        label: 'Last Name',
        name: 'lastName',
        key: 'lastName',
      },
      {
        type: 'text',
        label: 'Date of Birth',
        name: 'dob',
        key: 'dob',
      },
    ],
  },
]
export const fentanylPrescriptionSchema = [
  // {
  //   component: 'h3',
  //   children: 'Prescription Information',
  //   class: 'tw-text-2xl tw-mb-4',
  // },
  {
    component: 'div',
    class: 'tw-grid tw-grid-cols-6 tw-gap-6',
    children: [
      {
        type: 'text',
        label: 'Rx Number',
        name: 'rxNumber',
        key: 'rxNumber',
      },
      {
        type: 'text',
        label: 'Rx Date',
        name: 'rxDate',
        key: 'rxDate',
      },
      {
        type: 'text',
        label: 'Days',
        name: 'daySupply',
        key: 'daySupply',
      },
      {
        type: 'text',
        label: 'Brand Name',
        name: 'brandName',
        key: 'brandName',
      },
      {
        type: 'text',
        label: 'Generic Name',
        name: 'genericName',
        key: 'genericName',
      },
      {
        type: 'text',
        label: 'Rx Quantity',
        name: 'rxQuantity',
        key: 'rxQuantity',
      },
      {
        type: 'text',
        label: 'Rx Strength',
        name: 'rxStrength',
        key: 'rxStrength',
      },

    ],
  },
]
