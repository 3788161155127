<template>
  <div>
    <v-card
      id="patientformpanel"
      color="info"
      dark
    >
      <v-card-title class="justify-left">
        Patch-For-Patch Fentanyl Return Sheet
      </v-card-title>
      <v-row :key="componentTopCardKey">
        <v-col
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <h2>{{ patientProfile['FIRST NAME'] }} {{ patientProfile['LAST NAME'] }}</h2>
            <p>Gender: {{ patientProfile['GENDER'] }} || DOB: {{ this.$moment(this.patientProfile['DATE OF BIRTH']).format('YYYY-MM-DD') }} </p>
          </v-card-text>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <h2>{{ prePopulate.brandName }} {{ prePopulate.rxStrength }}</h2>
            <p> {{ prePopulate.rxNumber }}||Date: {{ prePopulate.rxDate }}||DIN: {{ prePopulate.din }}</p>
          </v-card-text>
        </v-col>
        <v-col
          cols="12"
          md="4"
          sm="4"
        >
          <v-card-text class="text-center">
            <h2>Dr. {{ patientProfile['PRIMARY FIRST NAME'] }} {{ patientProfile['PRIMARY LAST NAME'] }}</h2>
            <p>Fax: {{ patientProfile['PRIMARY FAX #'] }} || Tel: {{ (this.patientProfile['PRIMARY PHONE #']) }} </p>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>

    <v-stepper
      v-model="activeStep"
      non-linear
      class="custom-header"
    >
      <v-stepper-header>
        <v-stepper-step
          :complete="activeStep > 1"
          step="1"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Medication Selection</span>
              <span class="text--secondary text-xs">Please select Fentanyl Prescription from Patient File</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          step="2"
          :complete="activeStep > 2"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Data Review</span>
              <span class="text--secondary text-xs">Lets Gather the info</span>
            </div>
          </div>
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">03</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold">Form Finalization</span>
              <span class="text--secondary text-xs">Lets Document the Request</span>
            </div>
          </div>
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card
            id="medListId"
            :key="componentKeyMedList"
          >
            <single-medication-selection
              :searched="searched"
              :selected="selectedMedication"
              @medicationupdatelist="selected"
            >
            </single-medication-selection>
          </v-card>
        </v-stepper-content>
        <v-stepper-content step="2">
          <div class="tw-mt-5 tw-pb-8">
            <div class="tw-pt-6 d-flex justify-center align-center">
              <div class="tw-w-full tw-card tw-shadow-2xl lg:tw-card-side tw-bg-pglightblue tw-text-primary-content">
                <div class="tw-card-body">
                  <div class="tw-grid tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                    <p class="tw-font-black">
                      Prescription Details:
                    </p>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start">
                    <div>Dr. {{ this.prePopulate.FirstName }} {{ this.prePopulate.LastName }}</div>
                    <div>CPSO: {{ this.prePopulate.CPSO }}</div>
                    <div>Tel: {{ this.prePopulate.DoctorPhone }}</div>
                    <div>Fax: {{ this.prePopulate.DoctorFax }}</div>
                    <div>Rx #: {{ this.prePopulate.RxNumber }}</div>
                    <div>Rx Date: {{ this.prePopulate.RxDate }}</div>
                  </div>
                  <div class="tw-grid tw-pt-6 tw-grid-cols-1 tw-gap-4 tw-justify-items-start tw-items-start">
                    <p class="tw-font-black">
                      Medication Details:
                    </p>
                  </div>
                  <div class="tw-grid tw-grid-cols-1 tw-justify-items-start tw-items-start">
                    <div>{{ this.prePopulate.BrandName }} ({{ this.prePopulate.GenericName }}) {{ this.prePopulate.RxStrength }} {{ this.prePopulate.Form }}</div>
                    <p class="tw-font-black tw-pt-3">
                      Instructions:
                    </p>
                    <div>
                      {{ this.prePopulate.SIGFull }}
                    </div>
                  </div>
                  <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-justify-items-start tw-items-start tw-pt-6">
                    <div>Quantity: {{ this.prePopulate.RxQtyDispense }}</div>
                    <div>Days Supply: {{ this.prePopulate.Days }}</div>
                    <div>Total Quantity Authorized: {{ this.prePopulate.TotalAuthorized }}</div>
                    <div>Quantity Remaining: {{ this.prePopulate.QtyRemain }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <v-expansion-panels
            v-model="dataPanel"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Patient Information: {{ prePopulate.firstName }} {{ prePopulate.lastName }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                  <FormulateForm
                    :key="refreshFormulateForm"
                    v-model="prePopulate"
                    name="fentanylSchema"
                    :schema="fentanylSchema"
                    :keep-model-data="true"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Prescription Information: {{ prePopulate.brandName }} {{ prePopulate.rxStrength }}</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="tw-px-4 tw-py-5 tw-bg-white sm:tw-p-6 dark:tw-bg-darkmode">
                  <FormulateForm
                    :key="refreshFormulateForm"
                    v-model="prePopulate"
                    name="fentanylPrescriptionSchema"
                    :schema="fentanylPrescriptionSchema"
                    :keep-model-data="true"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-expansion-panels v-model="finalpanel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Signature </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <pg-sig-input
                  @updatePharmacist="updatePharmacist"
                  @save="saveSignature"
                >
                </pg-sig-input>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-base">Pharmacist Information: {{ patientProfile['rphfirstname'] }} </span>
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container>
                  <v-row
                    wrap
                    ml-4
                  >
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="12"
                      md="4"
                    >
                      <pharmacist-search></pharmacist-search>
                      <v-btn
                        id="v-step-5"
                        color="primary"
                        primary
                        class="my-3"
                        @click.native="openPharmacistOverlay"
                      >
                        Select a Pharmacist
                      </v-btn>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['rphfirstname']"
                        label="Pharmacist Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="auto"
                      xs="12"
                      sm="6"
                      md="3"
                    >
                      <v-text-field
                        v-model="patientProfile['ocp']"
                        label="OCP#"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-stepper-content>
      </v-stepper-items>
      <div

        class="d-flex justify-center align-center"
      >
        <v-card-actions>
          <pg-form-buttons
            :step="activeStep"
            :final-step="3"
            @next="next"
            @previous="previous"
          ></pg-form-buttons>
          <!-- <v-col>
            <v-btn
              v-show="activeStep === 1"
              color="success"
              large
              @click="nextstepone"
            >
              Next
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="warning"
              large
              @click="activeStep = 1"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 3"
              color="warning"
              large
              @click="activeStep = 2"
            >
              Previous
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              v-show="activeStep === 2"
              color="success"
              large
              @click="nextstep"
            >
              Next
            </v-btn>
          </v-col> -->
          <v-col>
            <pg-submit
              v-show="activeStep === 3"
              id="v-step-7"
              form-type="fentanyl"
              base-filename="fentanyl"
              type-of-medscheck="typeOfMedscheck"
              :patient-data-decorator="ProcessMeds"
              :patient-data="prePopulate"
              @saveInfo="savePdfInfo($event)"
            >
              Submit
            </pg-submit>
            <save-patient-state
              :patient-profile="prePopulate"
              :label="saveState"
              @onload="OnLoad($event)"
              @click="statekeyRender"
            ></save-patient-state>
          </v-col>
        </v-card-actions>
      </div>
    </v-stepper>
    <v-dialog
      v-model="loadingPatient"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text class="pt-3">
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mdiAlert, mdiCheckboxMarkedCircleOutline } from '@mdi/js'

import { fentanylSchema, fentanylPrescriptionSchema } from '@/assets/formSchema/fentanylSchema'
import SingleMedicationsSelection from '@/components/phoxTables/drug/singleDrug.vue'

// import SingleMedicationsSelection from '@/views/pages/rxSearch/SingleMedicationsSelectionNoRepeat.vue'
import PharmacistSearch from '@/components/common/ui/Form/PharmacistSearch/PharmacistSearch.vue'

import submitPdf from '@/components/common/ui/Form/Submit/ProcessPDF.vue'
import SavePatientState from '@/views/pages/tools/SaveDocumentState.vue'
import serverFormProcess from '@/views/Medscheck/Utils/processFormDataMedicationSelection'
import { stateService } from '@/render/api/zarya'
import pgSigInput from '@/components/common/ui/Form/pgSigInput/pgSigInput.vue'
import pgFormButtons from '@/views/patient/components/pg-form-buttons.vue'

export default {
  name: 'Fentanyl',
  components: {
    'single-medication-selection': SingleMedicationsSelection,
    'pg-submit': submitPdf,
    'save-patient-state': SavePatientState,
    'pharmacist-search': PharmacistSearch,
    'pg-sig-input': pgSigInput,
    'pg-form-buttons': pgFormButtons,
  },

  data() {
    this.$store.dispatch('setFormClass', 'fentanyl')

    return {
      searched: 'fentanyl',
      fentanylPrescriptionSchema,
      saveState: 'Save Progress',
      dateOfTransaction: '',
      dateTimeofTransaction: '',
      rxDetails: '',
      finalpanel: 0,
      dataPanel: '',
      refreshFormulateForm: 0,
      loadingPatient: false,
      componentKeyMedList: 0,
      singleSelect: true,
      componentTopCardKey: 0,
      selectedDoctor: [],
      prePopulate: {},
      search: '',
      doctorList: [],
      selectedMedication: [],
      icons: { mdiAlert, mdiCheckboxMarkedCircleOutline },
      activeStep: 1,
      initial: '',
      patientProfile: {},
      fentanylSchema,
      headers: [
        {
          text: 'Last Name',
          class: 'black--text font-weight-bold subtitle-1',
          align: 'left',
          value: 'DoctorLastName',
          sortable: false,
        },
        {
          text: 'First Name',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFirstName',
          sortable: false,
        },
        {
          text: 'CPSO',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'CPSO',
          sortable: false,
        },
        {
          text: 'Address',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorAddress',
          sortable: false,
        },
        {
          text: 'Phone',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorPhone',
          sortable: false,
        },
        {
          text: 'Fax',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'DoctorFax',
          sortable: false,
        },
        {
          text: 'Prescriptions in Last Year',
          class: 'black--text font-weight-bold',
          align: 'left',
          value: 'RXperProvider',
          sortable: false,
        },
      ],
      headersCard: [
        {
          text: 'Generic Name',
          align: 'left',
          sortable: false,
          value: 'GenericName',
        },
        {
          text: 'Strength',
          align: 'left',
          sortable: false,
          value: 'RxStrength',
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'FormClass',
      'PatientProfile', // get patient profile
      'PatientMedications', // get patient medication,
      'PharmacyProfile',
      'UserProfile',
    ]),
    ...mapState(['Patient']),
    conditionalSchema() {
      if (this.prePopulate.covidScreening !== 'Yes') {
        const children = this.fluConsentRadioSchema[0].children.filter(({ name }) => name !== 'seriousReaction')
        const schema = [
          {
            children,
            class: 'tw-grid tw-grid-cols-6 tw-gap-6',
            component: 'div',
          },
        ]

        return schema
      }
      const { children } = this.fluConsentRadioSchema[0]
      const schema = [
        {
          children,
          class: 'tw-grid tw-grid-cols-6 tw-gap-6',
          component: 'div',
        },
      ]

      return schema
    },
    conditionalConsent() {
      if (this.prePopulate.consentMyself !== 'agent') {
        const children = this.consentFluSchema[1].children.filter(
          ({ name }) =>
            name !== 'agentName' && name !== 'agentTel' && name !== 'agentRelation' && name !== 'agentRelationSpecify',
        )

        const schema = [
          {
            component: 'h3',
            children: 'Seasonal Influenza Vaccination Patient/Agent Consent',
            class: 'tw-text-2xl tw-mb-4',
          },
          {
            children,
            class: 'tw-grid tw-grid-cols-6 tw-gap-6',
            component: 'div',
          },
        ]

        return schema
      }
      if (this.prePopulate.agentRelation !== 'other') {
        const children = this.consentFluSchema[1].children.filter(({ name }) => name !== 'agentRelationSpecify')

        const schema = [
          {
            component: 'h3',
            children: 'Seasonal Influenza Vaccination Patient/Agent Consent',
            class: 'tw-text-2xl tw-mb-4',
          },
          {
            children,
            class: 'tw-grid tw-grid-cols-6 tw-gap-6',
            component: 'div',
          },
        ]

        return schema
      }
      const { children } = this.consentFluSchema[1]
      const schema = [
        {
          component: 'h3',
          children: 'Seasonal Influenza Vaccination Patient/Agent Consent',
          class: 'tw-text-2xl tw-mb-4',
        },
        {
          children,
          class: 'tw-grid tw-grid-cols-6 tw-gap-6',
          component: 'div',
        },
      ]

      return schema
    },
  },
  mounted() {
    // this.populateWithPatientData()
    this.preloadForm()
    this.searchDoctor()
    this.refreshFormulateForm += 1
    this.saveDateSubmitted()
    this.$root.$on('Refresh_Table', patientID => {
      // this.populateWithPatientData()
      this.saveDateSubmitted()
      this.searchDoctor()
      this.selectedPatient()
      this.preloadForm()
      this.refreshFormulateForm += 1
      this.step = '1'
      this.finalpanel = 0
    })
  },
  methods: {
    previous() {
      this.activeStep--
    },
    next() {
      this.activeStep++
      if (this.activeStep === 2) {
        this.populateWithPatientData()
        this.medicationsForPDF(this.patientData)
        this.refreshSignatureStatus += 1
      }
    },
    updatePharmacist({ pharmacistName, ocp }) {
      this.prePopulate.rphfirstname = pharmacistName
      this.prePopulate.ocp = ocp
      this.patientProfile.rphfirstname = pharmacistName
    },
    saveSignature({ pharmacistSig, signatureDate }) {
      this.prePopulate.PharmacistSignature = pharmacistSig
      this.prePopulate.rphsignature = pharmacistSig
      this.prePopulate.TODAYSDATE = signatureDate
    },
    selected(selectedMedication) {
      this.selectedMedication = selectedMedication
      this.patientProfile = {
        ...this.patientProfile,
        rphfirstname: `${this.$store.state.User.user.firstName} ${this.$store.state.User.user.lastName}`,
        ocp: this.$store.state.User.user.ocp,
        selectedMedication: this.selectedMedication,

        // originalRxnumber: this.selectedMedication[0].RxNumber,
      }
    },
    async searchDoctor() {
      this.doctorList =
        (await this.$root.$system.DBAdapterConn.getSearchByDoctor(
          this.PatientProfile.PatientID,
          this.drFirstName,
          this.drLastName,
          this.cpso,
        )) || []
    },
    openPharmacistOverlay() {
      window.searchPharmacist.open(({ PharmID, UserName }) => {
        this.patientProfile = Object.assign(this.patientProfile, {
          rphfirstname: UserName,
          ocp: PharmID,
        })
      })
    },
    async populateWithPatientData() {
      this.prePopulate = {
        ...this.patientProfile,
        fentanylSchema: this.fentanylSchema,
        lastName: this.PatientProfile['LAST NAME'],
        firstName: this.PatientProfile['FIRST NAME'],
        dob: this.$moment(this.PatientProfile['DATE OF BIRTH']).format('YYYY-MM-DD'),
        'dob[year]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format('YYYY'),
        'dob[month]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format('MM'),
        'dob[day]': this.$moment(this.PatientProfile['DATE OF BIRTH']).format('DD'),
        rphName: `${this.UserProfile.firstName} ${this.UserProfile.lastName}`,
        rxNumber: this.selectedMedication[0].RxNumber,
        rxDate: this.$moment(this.selectedMedication[0].RxDate).format('YYYY-MM-DD'),
        brandName: this.selectedMedication[0].BrandName,
        rxQuantity: this.selectedMedication[0].RxQtyDispense,
        rxStrength: this.selectedMedication[0].RxStrength,
        daySupply: this.selectedMedication[0].Days,
        dateSigned: this.$moment(this.patientProfile.dateSubmitted).format('YYYY-MM-DD'),
        genericName: this.selectedMedication[0].GenericName,
        pharmacyID: this.$store.getters.PharmacyProfile._id,
        userID: this.$store.getters.UserProfile._id,
        patientID: this.$store.getters.PatientProfile.PatientID,
        PharmacistSignature: this.prePopulate.PharmacistSignature,
        din: this.selectedMedication[0].DIN,
        RxNumber: this.selectedMedication[0].RxNumber,
        LastName: this.selectedMedication[0].LastName,
        FirstName: this.selectedMedication[0].FirstName,
        CPSO: this.selectedMedication[0].CPSO,
        DoctorPhone: this.selectedMedication[0].DoctorPhone,
        DoctorFax: this.selectedMedication[0].DoctorFax,
        RxDate: this.$moment(this.selectedMedication[0].RxDate).format('YYYY-MM-DD'),
        DIN: this.selectedMedication[0].DIN,
        GenericName: this.selectedMedication[0].GenericName,
        RxStrength: this.selectedMedication[0].RxStrength,
        Form: this.selectedMedication[0].Form,
        Schedule: this.selectedMedication[0].Schedule,
        BrandName: this.selectedMedication[0].BrandName,
        RxQtyDispense: this.selectedMedication[0].RxQtyDispense,
        QtyRemain: this.selectedMedication[0].QtyRemain,
        SIGFull: this.selectedMedication[0].SIGFull,
        QtyDispense: this.selectedMedication[0].QtyDispense,
        Days: this.selectedMedication[0].Days,
        TotalAuthorized: this.selectedMedication[0].TotalAuthorized,
      }
      this.loadingPatient = false
    },
    preloadForm() {
      this.patientData = {
        ...this.$store.state.Patient.data,
        PharmacistSignature: '',
      }
      this.medicationsList = this.$store.state.Patient.medications
      this.patientProfile = {
        ...this.$store.state.Patient.data,
      }
    },
    selectedPatient() {
      this.prePopulate = {}
      this.activeStep = 1

      this.componentTopCardKey += 1

      // this.componentKeyinteractions += 1
      this.componentKeyMedList += 1

      // this.populateWithPatientData()
    },
    savePdfInfo(data) {
      this.prePopulate.pdfRef = {
        ...data,
      }
      this.updateStateStatus()
    },
    selectedDoctorClick() {
      this.prePopulate = {
        ...this.prePopulate,
        drName: `${this.selectedDoctor[0].DoctorFirstName} ${this.selectedDoctor[0].DoctorLastName}`,
        drFax: this.selectedDoctor[0].DoctorFax,
        cpso: this.selectedDoctor[0].CPSO,
        drTel: this.selectedDoctor[0].DoctorPhone,
      }
    },
    saveDateSubmitted() {
      const current = new Date()
      const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`
      const time = `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
      const dateTime = `${date} ${time}`
      this.patientProfile.dateSubmitted = dateTime
      this.dateOfTransaction = date
      this.dateTimeofTransaction = dateTime
    },
    async updateStateStatus() {
      this.saveDateSubmitted()
      let res
      const data = {
        formType: this.$store.state.Patient.FORM_CLASS,
        state: this.prePopulate,
        patientID: this.patientProfile.PatientID,
        userID: this.UserProfile._id,
        pharmacyID: this.PharmacyProfile._id,
      }

      this.$router.push({
        name: 'dashboard-document',
        query: {
          tab: 4,
        },
      })
      const token = await this.$auth.getTokenSilently()
      try {
        if (!this.patientProfile.stateId) {
          // create it and make its status complete (this is in case they complete a medscheck and it doesnt have a stateid already -- not working )
          // eslint-disable-next-line no-undef
          res = await stateService.createState(token, {
            ...data,
          })
          this.patientProfile.stateId = res.state._id
        }

        // eslint-disable-next-line no-undef
        res = await stateService.updateState(token, this.patientProfile.stateId, {
          ...data,
          status: 'complete',
        })

        return res
      } catch (err) {
        console.log(err)
      }

      // update it - once medcheck is complete and it has state id, change to complete -- works
    },
    nextstepone() {
      this.populateWithPatientData()
      this.medicationsForPDF(this.patientData)
      this.activeStep = 2
      this.refreshSignatureStatus += 1
    },
    nextstep() {
      this.activeStep = 3
      this.refreshSignatureStatus += 1
    },
    ProcessMeds(patientData) {
      return serverFormProcess(this.selectedMedication, this.medicationsForPDF(patientData))
    },
    medicationsForPDF(patientData) {
      this.selectedMedication.forEach((med, idx) => {
        patientData = {
          ...patientData,
          [`rxNumbers${idx + 1}`]: `${med['RX NUMBER'] || med.RxNumber} - ${med['GENERIC NAME'] || med.GenericName} - ${
            med.STRENGTH || med.RxStrength
          } - Qty: ${med.RxQtyDispense} - Qty Auth:${med.TotalAuthorized}`,
        }
      })
      this.rxDetails = patientData

      return patientData
    },
    statekeyRender() {
      this.componentKeyinteractions += 1
    },
  },
}
</script>

<style lang="scss" scoped>
#patientformpanel {
  div.v-card__title.justify-left {
    color: rgb(248, 248, 248) !important;
  }
  div.row {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(1) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(2) div h2 {
    color: rgb(248, 248, 248) !important;
  }
  div.row div:nth-child(3) div h2 {
    color: rgb(248, 248, 248) !important;
  }
}

#headersTable {
  .theme--dark.v-data-table {
    background-color: #95a6df80 !important;
    color: #ffffff !important;
  }
  .theme--dark.v-data-table td {
    color: rgb(255 255 255) !important;
  }
}
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #000000, #000000);
  background-origin: border-box;
  background-clip: content-box, border-box;
  justify-content: center !important;
  align-content: center !important;
}
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
.Signaturecontainer {
  width: '100%';
  padding: 8px 16px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
#noPadding {
  padding: 0;
}
.fixedElement {
  /* position: fixed; */
  padding: 1% 2%;
  width: 100%;
  z-index: 20;
}
#buttons {
  margin: 1%;
}
.centerButtons {
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
.centerButtons button {
  margin: 0 2%;
}
iframe {
  width: 1px;
  min-width: 100%;
}
</style>
